.product-tile-container{
    height: 70vh;
    width: 48.5vh;
    box-sizing: border-box;
    /* margin: 5px; */
    padding: 2%;
    background-color: #191917;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.product-tile-img-container{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-tile-img-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 2/3;
    object-position: center;
}

.product-tile-details{
    height: auto;
    width: 100%;
    /* box-sizing: border-box;
    margin: 5px; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}
.product-heading{
    padding: 0;
    margin: 0;
    font-size: 0.95rem;
    font-weight: 700;
    font-family: 'Paciencia';
    color: #f8f8f8;
    text-align: left;
}

.product-price-text{
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
    font-weight: bold;
    font-family: 'Paciencia';
    color: #f8f8f8;
    text-align: left;
}
.product-unavailable-text{
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: 'Paciencia';
    color: #aeaeae;
    text-align: left;
}

.product-actions{
    height: auto;
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
}

@media only screen and (max-width: 600px) {
    .product-tile-container{
        height: 50vh;
    }
    .product-tile-img-container{
        height: 35%;
        width: 100%;
    }
    .product-tile-img-container img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 2/3;
        object-position: center;
    }

    .product-tile-details{
        height: auto;
        width: 100%;
        /* box-sizing: border-box;
        margin: 5px; */
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }
    .product-heading{
        text-align: center;
    }
    .product-actions{
        height: auto;
        box-sizing: border-box;
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: flex-start;
        align-items: center;
    }
}   
/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .product-tile-container{
        height: 30vh;
        width: 30vh;
    }
    .product-tile-img-container{
        height: 35%;
        width: 100%;
    }
    .product-tile-img-container img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 2/3;
        object-position: center;
    }

    .product-tile-details{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }
    .product-heading{
        text-align: center;
    }
    .product-actions{
        height: auto;
        box-sizing: border-box;
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: flex-start;
        align-items: center;
    }
}