.user-game-tile{
    height: 45vh;
    width: 40vh;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: flex-start;
    align-items: flex-start;
}
.user-game-tile-img-container{
    height: 30vh;
    width: 100%;
}
.user-game-tile-img-container img{
    height: 100%;
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
    object-position: top;
}
.user-game-info-box{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}
.user-game-info-options-and-heading{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-game-info-box-game-name{
    padding: 0;
    margin: 0;
    font-size: 0.95rem;
    font-weight: 600;
    color: #f8f8f8;
}
.user-game-info-box-game-subtext{
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
    color: #a0a0a0;
}
.progress-percentage-div{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.current-rank-box{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    align-items: left;
}
.current-rank-heading{
    font-size: 0.9rem;
    font-family: 'Paciencia';
    font-weight: normal;
    color: #FFE74C;
    padding: 0;
    margin: 0;
    text-align: left;
}
.current-rank-text{
    font-size: 2.5rem;
    font-family: 'Paciencia';
    font-weight: bold;
    color: #F8F8F8;
    padding: 0;
    margin: 0;
    text-align: left;
}
.change-icon-box{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: mediumorchid; */
}

.new-rank-box{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-evenly;
    align-items: left;
}
.new-rank-heading{
    font-size: 0.9rem;
    font-family: 'Paciencia';
    font-weight: normal;
    color: #FFE74C;
    padding: 0;
    margin: 0;
    text-align: left;
}
.new-rank-text{
    font-size: 2.5rem;
    font-family: 'Paciencia';
    font-weight: bold;
    color: #F8F8F8;
    padding: 0;
    margin: 0;
    text-align: left;
}


/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-game-tile{
        height: auto;
        width: 20vh;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .user-game-tile-img-container{
        height: 12vh;
        width: 100%;
    }
    .user-game-tile-img-container img{
        height: 100%;
        width: 100%;
        aspect-ratio: 2/3;
        object-fit: cover;
        object-position: top;
    }
    .user-game-info-box{
        height: auto;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /* gap: 5px; */
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .user-game-info-box-game-name{
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 0.75rem;
        font-weight: 600;
        color: #f8f8f8;
    }
    .user-game-info-box-game-subtext{
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 0.675rem;
        font-weight: 600;
        color: #a0a0a0;
    }
}
/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .user-game-tile{
        height: auto;
        width: 20vh;
        box-sizing: border-box;
        margin: 7.5px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-game-tile-img-container{
        height: 25vh;
        width: 100%;
    }
    .user-game-tile-img-container img{
        height: 100%;
        width: 100%;
        aspect-ratio: 2/3;
        object-fit: cover;
        object-position: top;
    }
    .user-game-info-box{
        height: auto;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .user-game-info-box-game-name{
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 0.95rem;
        font-weight: 600;
        color: #f8f8f8;
    }
    .user-game-info-box-game-subtext{
        padding: 0;
        margin: 0;
        text-align: left;
        font-size: 0.75rem;
        font-weight: 600;
        color: #a0a0a0;
    }
}