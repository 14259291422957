.search-page-container{
    height: 95.9vh;
    width: 98%;
    padding: 1%;
    overflow-x: hidden;
    background-color: #191917;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.search-page-container::-webkit-scrollbar{
    display: none;
}

.search-area{
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.search-input-area{
    height: 50%;
    width: 75%;
    background-color: transparent;
    padding: 1%;
    border-bottom: solid #f8f8f8;
    outline: none;
    border-left: none;
    border-top: none;
    border-right: none;
    color: #f8f8f8;
    font-size: 0.9rem;
    font-weight: 700;
    margin-right: 15px;
}

.search-filters{
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.search-filter-box{
    height: auto;
    width: auto;
    margin-right: 10px;
    padding: 1%;
    display: flex;
    align-items: center;
}

.search-filter-option{
    height: 35px;
    width: 100px;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-weight: 500;
    color: #f8f8f8;
    cursor: pointer;
}
.search-filter-option.checked{
    border-bottom: solid #FFE74C;
    font-weight: 700;
}
.search-filter-name{
    font-size: 0.9rem;
    font-weight: 600;
    color: #f8f8f8;
}

.search-bar{
    height: 5vh;
    width: 50%;
    padding: 0.5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #191917;
}
.search-bar input{
    width: 90%;
    height: 5vh;
    padding: 1%;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: solid #f8f8f8;
    color: #f8f8f8;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 400;
}

/* FOR MOBILE DEVICES */
@media only screen and (max-width: 600px) {
    .search-page-container{
        height: 95.9vh;
        width: 98%;
        padding: 1%;
        overflow-x: hidden;
        background-color: #191917;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .search-page-container::-webkit-scrollbar{
        display: none;
    }

    .search-bar{
        height: 5vh;
        width: 100%;
        padding: 0.5%;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #191917;
    }

    /* NEW SEARCH FEATURES */
    .search-area{
        height: auto;
        width: 96%;
        padding: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .search-input-area{
        height: 65px;
        width: 95%;
        background-color: transparent;
        padding: 1%;
        border: solid #f8f8f8;
        border-radius: 10px 10px 10px 10px;
        color: #f8f8f8;
        font-size: 0.9rem;
        font-weight: 700;
        margin-right: 15px;
    }

    .search-filters{
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
/* FOR MOBILE DEVICES */