.user-profile-area{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-info-area{
    height: 300px;
    width: 98%;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.user-info-area-overlay{
    height: 100%;
    width: 100%;
    background-color: #191917;
    opacity: 0.5;
    background-blend-mode: multiply;
    position: absolute;
}
.user-info-box{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}
.user-profile-pfp-box{
    height: 125px;
    width: 125px;
    border-radius: 50% 50% 50% 50%;
    background-color: #ffe74c;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-profile-pfp-box img{
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 50% 50%;
    object-fit: cover;
}
.user-profile-pfp-box-txt{
    font-size: 1.5rem;
    font-weight: 700;
    color: #191917;
}
.user-profile-username{
    font-size: 1.25rem;
    font-weight: 700;
    color: #f8f8f8;
}

.user-profile-tab-area{
    height: auto;
    width: 98%;
    box-sizing: border-box;
    margin: 7px;
    /* background-color: mediumaquamarine; */
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}
.user-profile-tab{
    height: 35px;
    width: 100px;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-weight: 500;
    color: #f8f8f8;
    cursor: pointer;
}
.user-profile-tab.active{
    border-bottom: solid #FFE74C;
    font-weight: 700;

}

.chosen-tab-area{
    height: auto;
    width: 100%;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .user-info-area{
        height: 135px;
        /* margin-left: 7px; */
    }
    .user-info-area-overlay{
        height: 100%;
        width: 100%;
        background-color: #191917;
        opacity: 0.5;
        background-blend-mode: multiply;
        position: absolute;
    }
    .user-profile-pfp-box{
        height: 75px;
        width: 75px;
    }
    .user-profile-pfp-box-txt{
        font-size: 0.95rem;
        font-weight: 700;
        color: #191917;
    }
    .user-profile-username{
        font-size: 0.9rem;
    }
    .user-profile-tab-area{
        height: 45px;
        width: 96%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 7px;
    }
    .user-profile-tab{
        height: 35px;
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 0.65rem;
        font-weight: 500;
        color: #f8f8f8;
        background-color: transparent;
    }
    .user-profile-tab.active{
        height: 35px;
        width: auto;
        border-bottom: solid #FFE74C;
        font-size: 0.8rem;
        font-weight: 600;
        color: #f8f8f8;
    }
}