.marketplace-homepage{
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.marketplace-heading-area{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
}
.marketplace-heading{
    font-size: 1.5rem;
    font-weight: 600;
    color: #f8f8f8;
    padding: 0;
    margin: 0;
}
.marketplace-subheading{
    font-size: 0.75rem;
    font-weight: 500;
    font-style: italic;
    color: #aeaeae;
    padding: 0;
    margin: 0;
}

.marketplace-search-area{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.marketplace-filter-area{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

.marketplace-filter-heading{
    font-size: 0.95rem;
    font-weight: 700;
    color: #f8f8f8;
    padding: 0;
    margin: 0;
}

.marketplace-product-list-container{
    height: auto;
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items:flex-start;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .filter-heading{
        font-size: 1.25rem;
        font-weight: 800;
        color: #f8f8f8;
        text-align: left;
        padding: 0;
        margin: 0;
        margin-top: 5px;
    }

    .marketplace-product-list-container{
        gap: 10px;
    }
}
/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .marketplace-product-list-container{
        display: flex;
        gap: 5px;
        justify-content: space-evenly;
    }
}