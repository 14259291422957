.product-page-container{
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-image-container{
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.product-image-area{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.product-image-area img{
    height: 100%;
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: contain;
}

.product-details-container{
    flex: 1;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* background-color: mediumorchid; */
}

.product-page-main-heading{
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-size: 2rem;
    font-family: 'Paciencia';
    font-weight: 800;
    color: #f8f8f8;
    text-align: left;
}

.product-page-sub-heading{
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-size: 0.95rem;
    font-family: 'Paciencia';
    font-weight: 600;
    color: #aeaeae;
    text-align: left;
}
.product-page-sub-heading-2{
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.85rem;
    font-family: 'Paciencia';
    font-weight: 600;
    font-style: italic;
    line-height: 20px;
    color: #c7c7c7;
    text-align: left;
}

/* FOR MOBILE DEVICES ONLY */
@media only screen and (max-width: 600px) {
    .product-page-container{
        min-height: 100vh;
        width: 100%;
        box-sizing: border-box;
        margin: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .product-image-area img{
        height: 100%;
        width: 100%;
        aspect-ratio: 3/4;
        object-fit: contain;
    }

    .product-details-container{
        flex: 0;
        min-height: 30vh;
        width: 100%;
        box-sizing: border-box;
        padding: 1.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
}
/* For tablets */
@media only screen and (min-width: 600px) and (max-width: 1279px){
    .product-page-container{
        min-height: 100vh;
        width: 100%;
        box-sizing: border-box;
        margin: 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .product-image-area{
        flex: 0;
        height: 70vh;
    }

    .product-image-area img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .product-details-container{
        flex: 0;
        min-height: 30vh;
        width: 100%;
        box-sizing: border-box;
        padding: 1.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}